import { makeStyles } from '@material-ui/core/styles';
import { CreateMargin } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        maxHeight: 100,
        ...CreateMargin(10, 0, 20, 0),
    },
    label: {
        textTransform: 'capitalize',
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #2D2554',
        fontSize: 14,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: `${theme.palette.primary.main} 0 0 0 0.01rem`,
            borderColor: theme.palette.primary.main,
        },
    },
    inputDisable: {
        backgroundColor: theme.palette.grey[200],
    },
}));

export default useStyles;
